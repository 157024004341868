import { createWebHistory, createRouter } from "vue-router";

import routes from "./routes";

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: "history",
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  const publicPages = ["/login", "/register", "/forgot-password"];
  const authpage = !publicPages.includes(routeTo.path);
  const loggeduser = localStorage.currentUser;
  console.log(loggeduser);
  console.log("authpage", authpage);
  if (authpage && !loggeduser) {
    router.push("/login");
  } else {
    console.log("Hello");
    next();
  }
});

export default router;

import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("./views/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("./views/account/register"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("./views/account/forgot-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("./views/account/logout"),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch("auth/logOut");
        } else {
          store.dispatch("authfack/logout");
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom }
        );
      },
    },
  },
  {
    path: "/allowances",
    name: "allowances",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Allowances/AllowancesPage"),
  },

  {
    path: "/billing-details",
    name: "billing-details",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/BillingDetails/BillingDetailsPage"),
  },

  {
    path: "/billing_invoices",
    name: "billing_invoices",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/BillingInvoices/BillingInvoicesPage"),
  },

  {
    path: "/branches",
    name: "branches",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Branches/BranchesPage"),
  },

  {
    path: "/browsers",
    name: "browsers",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Browsers/BrowsersPage"),
  },

  // {
  //   path: "/budget",
  //   name: "budget",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("./views/Budget/BudgetPage"),
  // },

  {
    path: "/cheques",
    name: "cheques",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Cheques/ChequesPage"),
  },
  {
    path: "/accounts",
    name: "accounts",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/AccountingPage/AccountsPage/index.vue"),
  },
  {
    path: "/reports",
    name: "reports",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/ReportsPage/index.vue"),
  },
  {
    path: "/customers",
    name: "customers",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Customers/CustomersPage"),
  },
  {
    path: "/customers_setup",
    name: "customers_setup",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Customers/AccountsSetup/index.vue"),
  },
  {
    path: "/customers_report",
    name: "customers_report",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Customers/ReportPage/index.vue"),
  },
  {
    path: "/accounts_setup",
    name: "accounts_setup",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/AccountsSetup/setupPage.vue"),
  },

  {
    path: "/payments_invoices",
    name: "payments_invoices",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Payments/PaymentsPage/index.vue"),
  },
  {
    path: "/payments_setup",
    name: "payments_setup",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Payments/AccountsSetup/index.vue"),
  },
  {
    path: "/new-payment",
    name: "new-payment",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Payments/AddPayment/index.vue"),
  },
  {
    path: "/edit-payment/:id",
    name: "edit-payment/:id",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Payments/EditPaymetns/index.vue"),
  },
  {
    path: "/suppliers",
    name: "suppliers",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Payments/AgentsPage/index.vue"),
  },
  {
    path: "/suppliers_report",
    name: "suppliers_report",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Payments/ReportsPage/index.vue"),
  },
  {
    path: "/payment_made",
    name: "payment_made",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Payments/PaymentMade/index.vue"),
  },

  {
    path: "/emp_file_types",
    name: "emp_file_types",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/Masters/DocumentTypes/index.vue"),
  },

  {
    path: "/emp_files",
    name: "emp_files",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/EmpFiles/EmpFilesPage"),
  },

  {
    path: "/emp-job-titles",
    name: "emp-job-titles",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/EmpJobTitles/EmpJobTitlesPage"),
  },

  {
    path: "/emp_sectors",
    name: "emp_sectors",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/EmpSectors/EmpSectorsPage"),
  },
  // human recource
  {
    path: "/emps",
    name: "emps",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/Emps/index.vue"),
  },
  {
    path: "/emps_setup",
    name: "emps_setup",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/AccountsSetup/index.vue"),
  },
  {
    path: "/emps/profile/:id",
    name: "profile",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/Emps/Profile/index.vue"),
  },
  {
    path: "/emps_reports",
    name: "emps_reports",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/ReportsPage/index.vue"),
  },
  {
    path: "/masters",
    name: "masters",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/Masters/index.vue"),
  },
  {
    path: "/expenses-sectors",
    name: "expenses-sectors",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/ExpensesSectors/ExpensesSectorsPage"),
  },

  {
    path: "/issue-types",
    name: "issue-types",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/IssueTypes/IssueTypesPage"),
  },

  {
    path: "/level_one_chart_of_accounts",
    name: "level_one_chart_of_accounts",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/LevelOneChartOfAccounts/LevelOneChartOfAccountsPage"),
  },
  {
    path: "/journal_entries",
    name: "journal_entries",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/ManualGournals/JournalEntries/index.vue"),
  },
  {
    path: "/journal_entries/:id",
    name: "journal_entry",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/ManualGournals/JournalEntry/index.vue"),
  },
  {
    path: "/add_journal",
    name: "add_journal",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/ManualGournals/AddJournal/index.vue"),
  },
  {
    path: "/level_three_chart_of_accounts_copy-1",
    name: "level_three_chart_of_accounts_copy-1",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "./views/LevelThreeChartOfAccountsCopy1/LevelThreeChartOfAccountsCopy1Page"
      ),
  },

  {
    path: "/level_two_chart_of_accounts",
    name: "level_two_chart_of_accounts",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/LevelTwoChartOfAccounts/LevelTwoChartOfAccountsPage"),
  },

  {
    path: "/main-chart-of-accounts-types",
    name: "main-chart-of-accounts-types",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/MainChartOfAccountsTypes/MainChartOfAccountsTypesPage"),
  },

  {
    path: "/monthly-salary-details",
    name: "monthly-salary-details",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/MonthlySalaryDetails/MonthlySalaryDetailsPage"),
  },

  {
    path: "/neighborhoods",
    name: "neighborhoods",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Neighborhoods/NeighborhoodsPage"),
  },

  {
    path: "/opening-balances",
    name: "opening-balances",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/OpeningBalances/OpeningBalancesPage"),
  },

  {
    path: "/accounting-peroids",
    name: "accounting periods",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPeroids/AccountingPeroidsPage.vue"),
  },

  {
    path: "/roles",
    name: "roles",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Roles/RolesPage"),
  },

  {
    path: "/salary-deduction",
    name: "salary-deduction",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/SalaryDeduction/index.vue"),
  },

  {
    path: "/salary_item_degrees",
    name: "salary_item_degrees",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/SalaryItemDegrees/SalaryItemDegreesPage"),
  },
  {
    path: "/salary-items",
    name: "salary-items",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/SalaryItems/SalaryItemsPage"),
  },
  // {
  //   path: "/salary-items-categories",
  //   name: "salary-items-categories",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () =>
  //     import("../views/SalaryItems/SalaryItemsCategories/index.vue"),
  // },
  {
    path: "/salary_items_branches",
    name: "salary_items_branches",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/SalaryItemsBranches/SalaryItemsBranchesPage"),
  },
  {
    path: "/add-branch-item/:id",
    name: "add-branch-item",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/SalaryItems/AddItem/index.vue"),
  },
  {
    path: "/sessions",
    name: "sessions",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Sessions/SessionsPage"),
  },

  {
    path: "/tariff",
    name: "tariff",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Tariff/index.vue"),
  },

  {
    path: "/branch-sites",
    name: "branch_sites",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/BranchSites/BranchSites"),
  },

  {
    path: "/ticket-resolutions",
    name: "ticket-resolutions",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/Crm/TicketResolutions/TicketResolutionsPage.vue"),
  },

  {
    path: "/tickets",
    name: "tickets",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Crm/Tickets/TicketsPage.vue"),
  },

  {
    path: "/transactions",
    name: "transactions",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Transactions/TransactionsPage"),
  },

  {
    path: "/user-roles",
    name: "user-roles",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/UserRoles/UserRolesPage"),
  },

  {
    path: "/users",
    name: "users",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Users/UsersPage"),
  },

  {
    path: "/monthly_employee_salary",
    name: "monthly_employee_salary",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/Monthly Employees Salary/MonthlySalary.vue"),
  },
  {
    path: "/employee_details/:id",
    name: "employee_details",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Employ Details/EmployeeDetails"),
  },

  {
    path: "/payroll",
    name: "payroll",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/Payroll/index.vue"),
  },
  {
    path: "/payroll_managment",
    name: "payroll_managment",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/Payroll/PayrollManagement/index.vue"),
  },
  {
    path: "/master_payroll",
    name: "master_payroll",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/Payroll/MasterPayroll/index.vue"),
  },
  {
    path: "/payroll_report",
    name: "payroll_report",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/Payroll Managment/Payroll Report/PayrollReport.vue"),
  },
  {
    path: "/emps_report",
    name: "emps_report",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/Employee Report/EmployeeReport.vue"),
  },
  {
    path: "/tickets_report",
    name: "tickets_report",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Crm/TicketsReport/TicketsReport.vue"),
  },
  {
    path: "/billing_report",
    name: "billing_report",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/BillingReport/BillingReport.vue"),
  },
  {
    path: "/tree",
    name: "tree",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Tree/Tree.vue"),
  },
  {
    path: "/accounting_transaction",
    name: "accounting_transaction",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "./views/AccountingPage/AccountingTransaction/AccountingTransaction.vue"
      ),
  },
  {
    path: "/revenues",
    name: "revenues",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "./views/AccountingPage/AccountingRevenues/AccountingRevenues.vue"
      ),
  },
  {
    path: "/cash_and_banks",
    name: "cash_and_banks",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/AccountingPage/CashAndBanks/index.vue"),
  },
  {
    path: "/add_cash",
    name: "add_cash",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "./views/AccountingPage/CashAndBanks/CashAccounts/AddAccount/index.vue"
      ),
  },
  {
    path: "/edit_cash/:id",
    name: "edit_cash/:id",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "./views/AccountingPage/CashAndBanks/CashAccounts/EditAccount/index.vue"
      ),
  },
  {
    path: "/add_bank",
    name: "add_bank",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "./views/AccountingPage/CashAndBanks/BankAccounts/AddAccount/index.vue"
      ),
  },
  {
    path: "/edit_bank/:id",
    name: "edit_bank/:id",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "./views/AccountingPage/CashAndBanks/BankAccounts/EditAccount/index.vue"
      ),
  },
  {
    path: "/general_ledger",
    name: "general_ledger",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/GeneralLedger/MonthlyGeneral.vue"),
  },
  {
    path: "/general_ledger/report/:id/:from/:to",
    name: "general_ledger_report",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "./views/AccountingPage/AccountingReport/GeneralLedger/GeneralLedger.vue"
      ),
  },
  {
    path: "/income_statement",
    name: "income_statement",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/IncomeStatement/IncomeStatement.vue"),
  },
  {
    path: "/financial_statments",
    name: "financial_statments",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/Financial Statement/FinancialStatement.vue"),
  },
  {
    path: "/cash-flow-statement",
    name: "cash-flow-statement",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Cashflow Statement/CashflowStatement"),
  },
  {
    path: "/bills_payable",
    name: "bills_payable",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/BillsPayable/BillsPayable.vue"),
  },
  {
    path: "/bills_receivable",
    name: "bills_receivable",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/BillsReceivable/BillsReceivable.vue"),
  },
  {
    path: "/trial_balance",
    name: "trial_balance",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Trial Balance/TrialBalance"),
  },
  {
    path: "/opening_balances",
    name: "opening_balances",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/OpeningBalances/OpeningBalancesPage"),
  },
  {
    path: "/asset_valuation",
    name: "asset_valuation",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Asset Valuation/AssetValuation"),
  },
  {
    path: "/month_general",
    name: "month_general",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/GeneralLedger/MonthlyGeneral.vue"),
  },
  {
    path: "/income_expenditure",
    name: "income_expenditure",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/IncomeExpenditure/IncomeExpenditure.vue"),
  },
  {
    path: "/debtors",
    name: "debtors",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/DebtorsPage/DebtorsPage.vue"),
  },
  {
    path: "/fixed-assets",
    name: "fixed-assets",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/FixedAssets/index.vue"),
  },
  {
    path: "/fixed-assets-reports",
    name: "fixed-assets-reports",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/FixedAssets/ReportsPage/index.vue"),
  },
  {
    path: "/disposed-assets",
    name: "disposed-assets",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/FixedAssets/DisposedAssetsPage/index.vue"),
  },
  {
    path: "/assets-classes",
    name: "assets-classes",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/FixedAssets/FixedAssetClasses/index.vue"),
  },
  {
    path: "/fixed-assets-setup",
    name: "fixed-assets-setup",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/FixedAssets/FixedAssetsSetup/index.vue"),
  },
  {
    path: "/assets-categories",
    name: "assets-categories",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/FixedAssets/FixedAssetCategories/index.vue"),
  },
  {
    path: "/add_asset_category",
    name: "add_asset_category",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/FixedAssets/FixedAssetCategories/AddCategory/index.vue"),
  },
  {
    path: "/fixed-assets/profile/:id",
    name: "fixed-assets-profile",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/FixedAssets/Profile/index.vue"),
  },
  {
    path: "/creditors",
    name: "creditors",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/CreditorsPage/CreditorsPage.vue"),
  },
  {
    path: "/bill_age_analysis_report",
    name: "bill_age_analysis_report",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "./views/AccountingPage/BillAgeAnalysisReport/BillAgeAnalysisReport.vue"
      ),
  },
  {
    path: "/income-statement",
    name: "income-statement",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/IncomeStatement/IncomeStatement.vue"),
  },
  {
    path: "/assets_depreciation",
    name: "assets_depreciation",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "./views/AccountingPage/AssetsDepreciation/AssetsDepreciation.vue"
      ),
  },
  {
    path: "/balance_sheet",
    name: "balance_sheet",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/Balance Sheet/BalanceSheet.vue"),
  },
  {
    path: "/budget",
    name: "budget",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Budget/index.vue"),
  },
  {
    path: "/budget_vs_actual_report",
    name: "budget_vs_actual_report",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Budget/ReportsPage/index.vue"),
  },
  {
    path: "/emps_attendes",
    name: "emps_attendes",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/Hr/Employees  Attendes/EmplyeesAttendes.vue"),
  },
  {
    path: "/emps_attends_detials/:id",
    name: "emps_attends_detials",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/Hr/Employee Attends Details/EmployeeAttendsdetails.vue"),
  },
  {
    path: "/transaction_manger",
    name: "transaction_manger",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/Accounting Manger/TransactionManger.vue"),
  },
  {
    path: "/emp_work_day",
    name: "emp_work_day",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/Hr/Employees Workday/EmployeesWorkday.vue"),
  },
];
